<template>
  <main id="content" role="main">
    <div class="container">
      <div class="d-md-flex justify-content-between align-items-center py-3">
        <div class="mb-1 mb-md-0">
          <h1 class="h4 mb-0">
            Internal Transactions&nbsp;<span
              class="small text-secondary"
            ></span>
            <p class="mb-0 text-break small"></p>
          </h1>
        </div>
      </div>
    </div>

    <div class="container space-bottom-2">
      <span id="ContentPlaceHolder1_lblAdResult"></span>
      <div id="ContentPlaceHolder1_mainrow" class="card">
        <div class="card-body">
          <div
            class="
              d-flex
              flex-wrap flex-xl-nowrap
              text-nowrap
              align-items-center
              ml-auto
            "
          ></div>
          <div
            class="table-responsive mb-2 mb-md-0"
            id="paywall_mask"
            v-loading="loading"
          >
            <table class="table table-hover">
              <thead class="thead-light">
                <tr>
                  <th scope="col">Txn Hash</th>
                  <th scope="col">
                    <div class="d-flex align-items-center">
                      <div class="mr-2">Block</div>
                    </div>
                  </th>
                  <th scope="col">
                    <a href="javascript:void(0)" @click="isAge = !isAge">{{
                      isAge ? "Age" : "Time"
                    }}</a>
                  </th>
                  <th scope="col">Type</th>
                  <th scope="col">
                    <div class="d-flex align-items-center">
                      <div class="mr-2">From</div>
                    </div>
                  </th>
                  <th></th>
                  <th scope="col">
                    <div class="d-flex align-items-center">
                      <div class="mr-2">To</div>
                    </div>
                  </th>
                  <th scope="col">Value</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in txns" :key="item._id">
                  <td>
                    <i
                      class="fa fa-check-circle"
                      style="margin-end: 0.3rem"
                      :class="{
                        'text-success': item.status,
                        'text-danger': !item.status,
                      }"
                    ></i>
                    <ItemHash :hash="item.hash" />
                  </td>
                  <td>
                    <a
                      href="#"
                      v-on:click.prevent="showBlock(item.blockNumber)"
                      >{{ item.blockNumber }}</a
                    >
                  </td>
                  <td class="showDate showAge" style="">
                    <TimeAge :timestamp="item.timestamp" :isAge="isAge" />
                  </td>
                  <td>
                    {{ item.tracer }}
                  </td>
                  <td>
                    <ItemAddress :address="item.from" :info="item.fromInfo" />
                  </td>
                  <td>
                    <span
                      v-if="item.from != item.to"
                      class="
                        btn btn-xs btn-icon btn-soft-success
                        rounded-circle
                      "
                      ><i
                        class="fas fa-long-arrow-alt-right btn-icon__inner"
                      ></i
                    ></span>

                    <span
                      v-else
                      class="
                        u-label u-label--xs u-label--secondary
                        color-strong
                        text-uppercase text-center
                        w-100
                        rounded
                        text-nowrap
                      "
                      >SELF</span
                    >
                  </td>
                  <td>
                    <ItemAddress :address="item.to" :info="item.toInfo" />
                  </td>
                  <td>{{ item.value }} RCH</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div id="ContentPlaceHolder1_pageRecords">
            <div class="d-md-flex justify-content-between my-3">
              <div
                class="d-flex align-items-center text-secondary mb-2 mb-md-0"
              ></div>
              <ul class="pagination pagination-sm mb-0">
                <li class="page-item">
                  <span class="page-link" v-on:click="execPage(-1)"
                    ><i class="fa fa-chevron-left small"></i></span
                  ><span class="sr-only">Previous</span>
                </li>
                <li Class="page-item disabled">
                  <span Class="page-link text-nowrap"
                    >Page
                    <strong class="font-weight-medium">{{
                      currentPage
                    }}</strong>
                    of
                    <strong class="font-weight-medium">{{
                      parseInt(total / pageNum) + 1
                    }}</strong></span
                  >
                </li>
                <li class="page-item" data-toggle="tooltip" title="">
                  <a
                    class="page-link"
                    href="javascript:void(0)"
                    v-on:click="execPage(1)"
                    aria-label="Next"
                    ><span aria-hidden="True"
                      ><i class="fa fa-chevron-right small"></i
                    ></span>
                    <span class="sr-only">Next</span></a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <div id="push"></div>
</template>

<script>
import { getName } from "../../public/assets/js/lang.js";
import { formatNumber } from "../../public/assets/js/utils.js";
import { PostNet, PostNetUrl } from "../../public/assets/js/myRequest.js";
import route from "../router/index";
import TimeAge from "./components/timeAge.vue";
import ItemAddress from "./components/itemAddress.vue";
import ItemHash from "./components/item_hash.vue";
export default {
  name: "Txs",
  data() {
    return {
      txns: [],
      currentPage: 1,
      total: 0,
      pageNum: 20,
      loading: false,
      isAge: true,
    };
  },
  components: {
    ItemAddress,
    ItemHash,
    TimeAge,
  },
  mounted() {
    this.loading = true;
    this.execPage(-2);
  },
  methods: {
    getName(str1, str2) {
      return getName(str1, str2);
    },
    formatDate(time, fmt) {
      time = time * 1000;
      let date = new Date(time);
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      }
      let o = {
        "M+": date.getMonth() + 1,
        "d+": date.getDate(),
        "h+": date.getHours(),
        "m+": date.getMinutes(),
        "s+": date.getSeconds(),
      };
      for (let k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
          let str = o[k] + "";
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length === 1 ? str : this.padLeftZero(str)
          );
        }
      }
      return fmt;
    },
    padLeftZero(str) {
      return ("00" + str).substr(str.length);
    },
    formatNumber(number, decimail) {
      return formatNumber(number, decimail);
    },

    // 页面的方法
    showBlock: function (blockNum) {
      // location.href = "block?b=" + blockNum;

      route.push({
        path: "/block",
        query: {
          b: blockNum,
        },
      });
    },
    showTransaction: function (item) {
      if (item.transferType) {
        if ("token" === item.transferType) {
          // location.href =
          //   "/tx/tokentx.html?h=" + item.hash + "&s=" + item.symbol;
          route.push({
            path: "/txTokenDetail",
            query: {
              h: item.hash,
              s: item.symbol,
            },
          });
          return;
        }
      }
      // location.href = "/tx/tx.html?h=" + item.hash;
      route.push({
        path: "/txDetail",
        query: {
          h: item.hash,
        },
      });
    },
    showAddressTransaction: function (addr) {
      // location.href = "/address?a=" + addr;
      route.push({
        path: "/address",
        query: {
          a: addr,
        },
      });
    },
    execPage: function (page) {
      if (page == -2) {
        this.currentPage = 1;
      } else {
        this.currentPage = parseInt(this.currentPage) + parseInt(page);
        if (isNaN(this.currentPage)) {
          this.currentPage = 1;
        }
        if (this.currentPage <= 0) {
          this.currentPage = 1;
          return;
        }
        let totalPage = parseInt(this.total / this.pageNum) + 1;
        if (this.currentPage > totalPage) {
          this.currentPage = totalPage;
          return;
        }
      }

      this.loading = true;
      PostNet.PostWith(
        PostNetUrl.coinInners,
        (info) => {
          this.loading = false;
          this.txns = info.data.data;
          this.total = info.data.count;
        },
        (err) => {
          this.loading = false;
        },
        { page: this.currentPage, pagesize: this.pageNum }
      );
    },
  },
};
</script>
